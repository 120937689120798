<template>
    <div>
        <b-card>
            <div class="mt-2">
                <app-view-table :title="trans('qu-queue-for-notifications',214)" :isTitleTotal="true" :filters.sync="filters" :loading="loading_list" :items="items" :fields="fields" :isFilterOptions="false" :customColumns="customColumns" :customColumnsHeaders="customHeaders" class="table-selectable" :key="refresh">
                
                    <template v-slot:head_selected>
                        <div @click.stop class="h-100 d-flex align-items-center justify-content-center">
                            <b-form-group class="pl-2">
                                <b-form-checkbox v-model="selected_all_rows" class="custom-control-primary" @change="selectAll()" style="margin-top:5px" size="md" />
                            </b-form-group>
                        </div>
                    </template>

                    <template v-slot:selected="cell">
                        <div @click.stop class="h-100 d-flex align-items-center justify-content-center">
                            <b-form-group class="pl-2">
                                <b-form-checkbox v-model="cell.data.item.selected" :key="refreshCheck" @change="select($event)" class="custom-control-primary" style="margin-top:5px" size="md" />
                            </b-form-group>
                        </div>
                    </template>

                    <template v-slot:client_location_name="cell">
                        <span class="app-ellips" :title="cell.data.item.client_location_name_full" > {{cell.data.item.client_location_name}} </span>
                    </template>

                    <template v-slot:client_department_name="cell">
                        <span class="app-ellips" :title="cell.data.item.client_department_name_full" > {{cell.data.item.client_department_name}} </span>
                    </template>

                    <template v-slot:function_shift="cell">
                        <span class="app-ellips" :title="cell.data.item.function_shift_full" > {{cell.data.item.function_shift}} </span>
                    </template>

                    <template v-slot:person_names="cell">
                        <span>{{cell.data.item.person_names}}</span>
                    </template>

                    <template v-slot:menu-items="cell">
                        <b-dropdown-item :to="{ name: 'planning-shifts-profile', params: { id_shift_data_main: cell.data.item.id_shift_data_main }}" >{{trans('to-service',200)}}</b-dropdown-item>
                        <b-dropdown-item v-if="user_role < 3 || cell.data.item.can_user_edit" @click="send(cell.data.item, 1)">{{trans('qu-send',224)}}</b-dropdown-item>
                        <b-dropdown-item v-if="user_role < 3 || cell.data.item.can_user_edit" @click="remove(cell.data.item, 1)">{{trans('decline',175)}}</b-dropdown-item>
                    </template>


                   <template v-slot:buttons>
                        <b-dropdown v-if="selected_rows > 0" no-caret toggle-class="app-button-multi mr-4" variant="secondary-grey">
                            <template #button-content>
                               {{trans('action',3)}} ({{selected_rows}})                         
                            </template>  
                            <b-dropdown-item @click="send(null, 2)">{{trans('qu-send',224)}}</b-dropdown-item>      
                            <b-dropdown-item @click="remove(null, 2)">{{trans('decline',175)}}</b-dropdown-item>                 
                        </b-dropdown>
                        
                    </template>

                </app-view-table>
            
            </div>  
        </b-card>
    </div>
</template>
<script>

import axios from "@axios";
import AppAlerts from '@core/scripts/AppAlerts';
import UserSettings from '@core/scripts/UserSettings';

  export default {

    props: [],
    
    components: {
    },

    mounted() {
        this.onCreateThis();
    },

    data() {
        return {
            customColumns: ["selected", "client_location_name","client_department_name", "function_shift", "person_names"],
            customHeaders: ['selected'],
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {                        
                    organizations: { type: 'list', changed: 0, values: [], function: "getClients", parameters: null, widthClass:'app-width-min-350'},
                    client_locations: { type: 'list', changed: 0, values: [], function: "getClientLocations", parameters: null, widthClass:'app-width-min-350'},
                    client_departments: { type: 'list', changed: 0, values: [], function: "getClientDepartments", parameters: null, widthClass:'app-width-min-350'},
                    function_shift: { type: 'list', changed: 0, values: [], function: "getFunctions", parameters: null, widthClass:'app-width-min-350'},
                    locations: { type: 'list', changed: 0, values: [], function: "getLocations", parameters: null, widthClass:'app-width-min-350'},    
                    queue_statuses: { type: 'list', changed: 0, values: [], function: "getShiftStatuses", parameters: null, widthClass:'app-width-min-350'},
                },
                page: 1,
                per_page: 10,
                search: "",                
                changed: 0,
                list_type: 3,
                type: 1,
            },
            fields: [
                { key: "selected", label: "",tdClass:"p-0", thClass:"p-0", visible: true  },
                { key: "organization_name", label: this.trans('organization',169), thStyle: { "min-width": "100px", "max-width": "100px" }, visible: true, filtering: true, filtering_name: "organizations"},
                { key: "client_location_name", label: this.trans('location',3), thStyle: { "min-width": "110px", "max-width": "110px" }, visible: true, filtering: true, filtering_name: "client_locations"},
                { key: "client_department_name", label: this.trans('department',182), thStyle: { "min-width": "110px", "max-width": "110px" }, visible: true, filtering: true, filtering_name: "client_departments"},
                { key: "function_shift", label: this.trans('function',3), thStyle: { "min-width": "80px", "max-width": "80px"}, visible: true, filtering: true, filtering_name: "function_shift"},               
                { key: "office_location_name", label: this.trans('branch',182), thStyle: { "min-width": "100px", "max-width": "100px" }, visible: true, filtering: true, filtering_name: "locations"},
                { key: "week_date", label: this.trans('week',3), thStyle: { "min-width": "60px", "max-width": "60px" }, visible: true, filtering: false},
                { key: "shift_date", label: this.trans('date',182), thStyle: { "min-width": "100px", "max-width": "100px" }, visible: true, filtering: false},
                { key: "shift_start_time", label: this.trans('planning-begin',209), thStyle: { "min-width": "60px", "max-width": "60px" }, visible: true, filtering: false},
                { key: "shift_end_time", label: this.trans('planning-end',209), thStyle: { "min-width": "60px", "max-width": "60px" }, visible: true, filtering: false},
                { key: "shift_status_name", label: this.trans('qu-service-status',225), thStyle: { "min-width": "100px", "max-width": "100px" }, visible: true, filtering: true, filtering_name: "queue_statuses"},
                { key: "person_names", label: this.trans('employee',5), thStyle: { "min-width": "120px", "max-width": "120px" }, visible: true, filtering: false},
                
            ],
            items: [],
            alertClass: new AppAlerts(),
            userSettings: new UserSettings(),
            loading_list:false,
            refresh:0,
            refreshCheck:0,
            selected_rows:0,
            selected_all_rows: false,
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
        };
    },

    methods: {
        
        getList() {
           this.loading_list = true;
           axios
                .post("planning/shifts/queue/getList", {
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {                 
                    this.filters.page = res.data.page;
                    this.items = res.data.items;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                    this.loading_list = false;
                    this.selected_all_rows = false;
                    this.selected_rows = 0;
                    this.items.list.forEach(item => {
                        item.selected = false;
                    });
                });
        },

        remove(item, type){

             this.alertClass.openAlertConfirmation(this.trans('confirm-message',180)).then(res => {

                if(res == true){

                    this.loading_list = true;
                    
                    let postData = {
                        filters: JSON.stringify(this.filters),
                        ids_shift_data_main: null,
                        return_type: "queue_list"
                    };

                    if(type == 1){//single
                        postData.ids_shift_data_main = JSON.stringify([item.id_shift_data_main]);
                    }else{//multi
                        var userRole = this.user_role;
                        let ids_shift_data_main = this.items.list.filter(function(value) {
                                                    return value.selected == true && (userRole < 3 || value.can_user_edit); }).map(a => a.id_shift_data_main);
                        postData.ids_shift_data_main = JSON.stringify(ids_shift_data_main);
                    }

                    axios
                            .post("planning/shifts/queue/remove", postData)
                            .then((res) => {
                                this.filters.page = res.data.page;
                                this.items = res.data.items;
                            })
                            .catch((error) => {
                                console.error(`error during request: ${error}`);
                            }).finally(() => {
                                this.selected_rows = 0;
                                this.selected_all_rows = false;
                                this.loading_list = false;
                                this.items.list.forEach(item => {
                                    item.selected = false;
                                });
                            });

                }

             });            

        },

        send(item, type){

            this.alertClass.openAlertConfirmation(this.trans('confirm-message',180)).then(res => {

                if(res == true){

                    this.loading_list = true;

                    let postData = {
                        filters: JSON.stringify(this.filters),
                        ids_shift_data_main: null,
                        return_type: "queue_list"
                    };

                    if(type == 1){//single
                        postData.ids_shift_data_main = JSON.stringify([item.id_shift_data_main]);
                    }else{//multi
                        var userRole = this.user_role;
                        let ids_shift_data_main = this.items.list.filter(function(value) {
                                                    return value.selected == true && (userRole < 3 || value.can_user_edit); }).map(a => a.id_shift_data_main);
                        postData.ids_shift_data_main = JSON.stringify(ids_shift_data_main);
                    }

                    axios
                            .post("planning/shifts/queue/send", postData)
                            .then((res) => {
                                this.filters.page = res.data.page;
                                this.items = res.data.items;
                            })
                            .catch((error) => {
                                console.error(`error during request: ${error}`);
                            }).finally(() => {
                                this.selected_rows = 0;
                                this.selected_all_rows = false;
                                this.loading_list = false;
                                this.items.list.forEach(item => {
                                    item.selected = false;
                                });
                            });

                }

            });

        },

        saveSettings() {
            this.userSettings.saveSettings(this.filters, 8);
        },

        async loadSettings() {
           var getData = JSON.parse(localStorage.getItem('userSettings'));

            if(getData == undefined || getData.shiftsQueueList == undefined){
                await this.userSettings.saveSettings(this.filters, 8);
                getData = JSON.parse(localStorage.getItem('userSettings'));
            }

            var sFilters = getData.shiftsQueueList;
            this.filters.filtering = sFilters.filtering;
            this.filters.sorting = sFilters.sorting;
            this.filters.list_type = sFilters.list_type;
            this.filters.per_page = sFilters.per_page != undefined ? sFilters.per_page : 10; 

        },

        selectAll(){
            if(this.selected_all_rows){
                this.items.list.forEach(element => {
                    element.selected = true;
                });

                this.selected_rows = this.items.list.length;

            } else {
                this.items.list.forEach(element => {
                    element.selected = false;
                });
                this.selected_rows = 0;
            }
            this.refreshCheck++;
        },

        select(){
            if (this.items != undefined && this.items.list != undefined) {
                let selected = this.items.list.filter(function(value) { 
                    return value.selected == true; 
                }).length;

                if(this.items.list.length == selected){
                    this.selected_all_rows = true;
                }else{
                    this.selected_all_rows = false;
                }
                
                var userRole = this.user_role;
                let selected_all = this.items.list.filter(function(value) { 
                    return value.selected == true && (userRole < 3 || value.can_user_edit); 
                }).length;
                this.selected_rows = selected_all;
            }else{
                this.selected_rows = selected_all;
            }
        },

        onCreateThis() { 
       
            this.loadSettings();
            this.getList();

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.organizations.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.client_locations.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.client_departments.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.function_shift.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.locations.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings();
                } 
            });

            this.$watch('filters.filtering.queue_statuses.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings();
                } 
            });
            
            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });
           
            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });

        },

    },
  };
</script>
<style scoped>
.app-local-sticky {
    position: absolute !important;
    right: 0;
    top: auto;
}
.tooltip{
    opacity: 1 !important;
}

</style>
